.review-item {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.review-item video,
.review-item img {
    max-width: 200px;
    margin: 5px;
}

.review-item .review-item-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 10px;
}

.comment {
    text-wrap: wrap;
    word-wrap: break-word;
}