.initials-avatar {
    width: 36px;
    height: 36px;
    cursor: pointer;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    background-color: #09e6a7;
    display: inline-block;
    box-sizing: border-box;
    font-size: 10px;

    div {
        color: #000;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: inherit;
        font-weight: inherit;
    }
}