.dropdown-menu {
    max-height: 280px;
    overflow-y: auto;
}

.table-responsive {
    overflow: visible;
    overflow-x: auto !important;
}

@media (max-width:500px) {
    header {
        button {
            i {
                vertical-align: middle;
                justify-content: center;
                display: inline-block;
            }
        }

        span {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            display: inline-block;
            max-width: 130px;
            vertical-align: middle;
        }
    }
}

.hamburger-icon.open {
    height: 30px !important;
}

.fs-bold {
    font-weight: bold;
}


.alert-item {
    transition: transform 0.5s ease, opacity 0.5s ease;
}

.alert-item.removing {
    transform: translateX(100%);
    opacity: 0;
}